* {
  // ? For topbar submenu
  ul[id*="popup"] {
    background: #1C1C1C !important;
  }
}

// ? To apply the background image
.bg-layout {
  .site-layout {
    .outer-wrapper {
      .outer-content {
        background-image: url('../Assets/img/educom-background.png');
        background-size: cover;
      }
    }
  }
}

// ? Top bar
.top-bar {
  .site-layout {
    .logo {
      float: left;
      width: 60px;
      height: 43px;
      margin: 16px 24px 16px 0;
      background-image: url('../Assets/img/educom-new-logo.svg');
      background-size: cover;
    }
  }
}

.full-content-with-topbar {
  .outer-wrapper {
    min-height: 98.5vh;

    .outer-content{
      height: 100%;
    }
  }
}


.fixed-content {
  // ? It can overflow, this is for pages where the content is not too long to scroll
  .outer-wrapper {
    height: 100vh;

    .outer-content{
      height: 100%;
    }
  }
}

.outer-screen {

  .site-layout {
    --background-color: #1C1C1C ;

    .layout-header {
      background-color: #1C1C1C ;
      display: flex;
      align-items: center;

      .header-menu {
        background-color: #1C1C1C ;
        flex: auto;
        min-width: 0;
      }
    }
  }
  .contact-info {
    color: white;
    line-height: 2;
    margin-left: 10px;
    span {
      display: block;
    }
  }
}
