.filter-container-wrapper {
  border-radius: 10px;
  width: 80%;
  .title {
    margin-top: 10px;
  }

  .subtitle {
    opacity: 0.7;
  }

  .description {
    margin-bottom: 10px;
  }

  .label{
    margin: 0 0 3px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: -0.24px;
    text-align: left;
    color: #8f9bb3;
  }

  .container-buttons {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 90%;
    height: 50px;
    max-height: 120px;
    background: #d2e2fc;
    border-radius: 12px;
    .user-type-button {
      font-size: 16px;
      font-weight: 500;
      border: none;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      color: #3C4043;
    }

  }

  .form-item {
    .ant-select-selector {
      font-family: 'Open Sans', sans-serif;
      border-radius: 8px !important;
      background-color: #ffffff;
      border-color: #e9e9e9;
      min-height: 50px;
      align-items: center;
    }
  }
}

.CustomButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  border-radius: 10px;
  font-size: 16px;
  border: none;
  background-color: #ecf3f8;
  color: #8f9bb3;
}
