.footer {
  background-color: #1C1C1C ;
  color: white;
  text-align: center;
  padding: 20px;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
}


.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container{
  float: left;
  width: 90px;
  height: 65px;
  margin: 16px 24px 16px 0;
  background-image: url('../Assets/img/educom-new-logo.svg');
  background-size: cover;
}

.contact-info {
  text-align: left;
}
