/* eslint-disable indent */
.card-adviser {
  margin: 0.3rem;
  .card-desing {
    width: 350px;
    max-height: 600px;
    flex-grow: 0;
    margin: 8px 8px 4px;
    padding: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  .titulo{
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: -0.2px;
    text-align: left;
    color: #3c4043;
  }
  .subtitulo{
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: -0.15px;
    text-align: left;
    color: #3c4043;
  }
  .label{
    margin: 0 0 3px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: -0.24px;
    text-align: left;
    color: #8f9bb3;
  }
  .texto{
    flex-grow: 0;
    margin: 3px 12px 3px 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.14px;
    text-align: left;
  }
  .flex-and-directions-columns {
    display: flex;
    flex-direction: column;
  }
  .cost_by_hour-container {
    margin-top: 1px;
    padding-top: 0;
  }
  .images-cards {
    width: 100%;
    height: 5.4rem;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
  }
  .btn-info{
    width: 100%;
    height: 34px;
    flex-grow: 0;
    margin: 4px 4px 4px 1px;
    padding: 3px 15.1px 3px 15.1px;
    border-radius: 16px;
    background-color: #ecf3f8;
  }
  .btn-primary{
    width: 100%;
    height: 52px;
    flex-grow: 0;
    margin: 4px 0 4px 4px;
    padding: 10px 15px 10px;
    border-radius: 8px;
    background-color: #2e60f5;
  }
  .opacity-style {
    opacity: 0.7;
  }
  .divider-style {
    margin: 0;
    &.small-margin {
      margin-top: 4px;
      margin-bottom: 4px;
    }
    &.large-margin {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
}
