// TODO: Fix styles for high resolution screens & for zoom in/out
.signup-adviser-screen {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .main-container {
    height: 85%;
    width: 50%;
    background-color: #fff;

    box-shadow: 0 40pt 50pt 0 rgba(0,0,0,.35);

    .bg-container {
      height: 100%;
      background-size: cover;

      .grey-layer {
        height: 100%;
        background-color: rgba(0,0,0,.4);
        display: flex;
        justify-content: center;
        align-items: center;

        .advertising {
          width: 75%;
          color: #FFF;

          label {
            font-weight: 600;
          }

        }
      }
    }

    .form-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .signup-form {
        height: 90%;
        width: 80%;
        h2 {
          text-align: center;
        }

        .submit-button {
          text-align: center;
          margin-top: 5%;
          margin-bottom: 5%;

          .a-btn {
            width: 100%;
            background-color: #EF3900;
            border-color: #EF3900;
            border-radius: 5px;
          }

          .a-btn:hover {
            background-color: #FF5F2E;
            border-color: #FF5F2E;
          }
        }
      }

    }
  }
}

// * ----------------------------------------------------------------------------------------------------------------- * //
// ? Full HD
@media screen and (max-width: 1920px) {
  .signup-adviser-screen {
    .main-container {

      .bg-container {
        .grey-layer {
          .advertising {
            label {
              font-size: xx-large;
            }
          }
        }
      }

      .form-container {
        .signup-form {
          height: 95%;

          h2 {
            font-size: xx-large;
          }
        }
      }
    }
  }
}

// * ----------------------------------------------------------------------------------------------------------------- * //
// ? Common HD
@media screen and (max-width: 1440px) {
  .signup-adviser-screen {
    .main-container {

      .bg-container {
        .grey-layer {
          .advertising {
            label {
              font-size: x-large;
            }
          }
        }
      }

      .form-container {
        .signup-form {

          .ant-form-item-explain-error {
            font-size: smaller;
          }

          .ant-form-item-extra {
            font-size: smaller;
          }

          h2 {
            font-size: large;
          }

          label, input {
            font-size: smaller;
          }

          p {
            font-size:xx-small;
          }
        }
      }
    }
  }
}
