// About us section
.about-us-container {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../../public/img/Educom.webp');
    background-size: cover;
    background-position: center;
    filter: brightness(35%);
}

.content-about-us {
    position: relative;
    z-index: 1;
    color: white;
    padding: 20px;
}

.content-about-us h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: bold;
    color: white;
    text-align: center;
}

.content-about-us p {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: white;
    margin: auto;
    text-wrap: pretty;
    max-width: 1600px;
}

@media (max-width: 768px) {
    .content-about-us h1 {
        font-size: 2rem;
        margin-bottom: 10px;
    }

    .content-about-us p {
        font-size: 1rem;
        margin-bottom: 10px;
    }
}


// Company section
.company-container {
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.about-company {
    flex: 1;
    text-align: left;
}

.about-company h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    font-weight: bold;
    color: #3498db;
}

.about-company p {
    text-wrap: pretty;
    padding: 20px;
    margin-bottom: 0%;
}

.company-image {
    flex: 1;
    text-align: center;
}

.company-container h2 {
    font-size: 2rem;
    padding-left: 20px;
}

.company-container p {
    font-size: 1.2rem;
}

.company-image img {
    max-width: 100%;
    height: auto;
}


// Unique factors section
.unique-factors-container {
    background: #f5f5f5;
    padding: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.unique-factors-container h2 {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2px;
    color: #3498db;
}

.unique-factors-container h3 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    color: #cccccc;
}

.unique-factors-cards {
    display: flex;
    justify-content: center;
    gap: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
}

.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    max-height: 250px;
    overflow: hidden;
}

.card-container .ant-card-meta-title {
    margin-top: 20px;
}

.card-container .ant-card-meta-description {
    margin-top: 20px;
}

.card-container .ant-card-meta-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// our team section
.our-team-container {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 20px;
}

.our-team h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
    color: #3498db;
}

.our-team p {
    font-size: 1.2rem;
    text-align: center;
}

.team-members-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.team-members-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.team-member {
    text-align: center;
    margin: 20px;
}

// work section
.work-section {
    background-color: #3498db;
    padding: 40px;
    text-align: center;
}

.work-title {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.work-description {
    color: #fff;
    font-size: 16px;
    margin-bottom: 20px;
}

.work-link {
    margin-top: 20px;
}

.work-section Button {
    background-color: #fff;
    color: #3498db;
    border: 1px solid #fff;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    width: 200px;
    height: 50px;
    cursor: pointer;
}

.fototeca h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
    margin-top: 50px;
    color: #3498db;
}

.fototeca h3 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    color: #cccccc;
}

.carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e6e6e6;
}