.success-screen {
  height: 100vh;
  background-size: cover;

  .bg-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.493);

    .message-container {
      margin: 0 auto;
      height: 80%;
      width: 35%;
      background-color: #fff;
      padding: 2%;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: column;
      align-items: center;
      .signature {
        text-align: center;
      }
      .draw-container {
        height: 40%;
        width: 70%;

        .success-draw {
          height: 100%;
          width: 100%;
        }
      }

      h2 {
        color: #ffb500;
        font-weight: 600;
        font-size: xx-large;

        @media screen and (max-width: 1440px) {
          font-size: x-large;
        }
      }

      p {
        font-size: large;

        @media screen and (max-width: 1440px) {
          font-size: small;
        }
      }

      label {
        color: #293b91;
        font-weight: 600;
        font-size: x-large;

        @media screen and (max-width: 1440px) {
          font-size: large;
        }
      }
    }
  }
}
