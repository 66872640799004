.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #030000;
  color: #fff;

  .redirect-message {
    display: none;
  }

  &.redirect-active {
    .redirect-message {
      display: block;
    }
  }

  .logo-img {
    width: 100%;
  }

  .form-card {
    text-align: center;

    .title {
      margin-bottom: 1rem;
    }

    .text-description {
      margin-bottom: 3rem;
    }

    .login-form-container {
      margin-top: 2rem;

      .ant-input-password {
        input {
          color: #fff;
        }
      }
    }

    .submit-button {
      width: 100%;
      margin-bottom: 1rem;
      background-color: #40a9ff;
      color: #fff;
    }

    .back-link {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;

      .anticon-arrow-left {
        margin-right: 0.5rem;
        transform: rotate(180deg);
      }
    }
  }
}
