.custom-sidebar {
  background-color: #293B91;
  color: #FFF;
  font-weight: bold;
  .ant-menu {
    .ant-menu-item-selected {
      background-color: #F5B14B;
      color: #000;
    }
  }
}

.screen {
  .wrapper {
    height: 100vh;
    .ant-layout-content {
      max-height: 80%;
      overflow: auto;
    }
  }
  .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .logo {
    height: 70px;
    margin: 16px;
  }

  .site-layout-background {
    background: #fff;

    .search-card {
      width: 400px;
      min-height: 80%;
      max-height: 80%;
      border-radius: 10px;

      .ant-card-body {

        @media screen and (max-width: 1920px) {
          height: 13vh;
        }

        @media screen and (max-width: 1600px) {
          height: 17vh;
        }
      }

      .rating {
        // ! NOTE: p tag does not work centering the content like this
        display: flex;
        align-items: center;
        margin-bottom: 5%;
      }
    }

    .ant-list-item {
      display: flex;
      justify-content: center;
      margin-bottom: 15%;
    }
  }
}
