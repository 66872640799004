.client-profile-container {
  margin-top: 24px;
  display: flex;
  justify-content: space-evenly;

  .profile-card {
    padding: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    text-align: center;

    .profile-picture-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;

      .profile-picture {
        width: 200px;
        height: 200px;
        border-radius: 50%;
      }
    }

    .upload-btn {
      margin-top: 5%;
      margin-bottom: 5%;
    }

    .info-text {
      margin-bottom: 8px;
    }
  }
}
