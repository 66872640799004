.login-screen {
  height: 100vh;

  .main-container {
    height: 100%;

    .bg-container {
      background-color: #FFF !important;
      .wrapper {
        height: 100%;
        padding: 1em;
  
        .bg-image {
          background-position-x: center;
          background-size: cover;
          box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
          height: 100%;
          display: flex;
          justify-content: center;

          .bg-logo {
            margin-top: 20%;
            height: 9.5%;
            width: 45%;

            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }

    .login-form {
      background-color: #FFF !important;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      a {
        font-weight: 600;
        font-size: medium;
        text-align: center;
      }

      .spacer {
        padding-left: 5em;
        width: 90%;

        .social-media-options {
          display: flex;
          width: 67.5%;
          margin-top: 3%;
          justify-content: space-around;

          .social-icon {
            display:block;
            height: 10%;
            width: 10%;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2%;

            img {
              height: 100%;
              width: 100%;
            }
          }

          .facebook {
            background-color: #1877F2;
          }

          .google {
            background-color: #FFF;
            border: #dbdbdb 1px solid;
          }
        }

        @media screen and (max-width: 767px) {
          padding: 0 2em;
          width: 100%;
        }

        .ant-typography {
          margin-bottom: 0;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .ant-col-12:first-child {
        display: none;
      }

      .ant-col-12:last-child {
        min-width: 100%;
      }
    }
  }
}
