.card-group {
  margin: 0.3rem;
  background: #f5f5f5;
  .card-desing {
    width: 350px;
    max-height: 660px;
    flex-grow: 0;
    margin: 8px 8px 4px;
    padding: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  .titulo{
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: -0.2px;
    text-align: left;
    color: #3c4043;
  }
  .subtitulo{
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: -0.15px;
    text-align: left;
    color: #3c4043;
  }
  .label{
    margin: 0 0 3px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: -0.24px;
    text-align: left;
    color: #bdc4d2;
  }
  .texto{
    flex-grow: 0;
    margin: 3px 12px 3px 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.14px;
    text-align: left;
  }

  .nota{
    flex-grow: 0;
    margin: 3px 12px 3px 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.14px;
    text-align: left;
    color: #BDC4D2FF;
  }

  .btn-whats-app{
    width: 100%;
    height: 34px;
    flex-grow: 0;
    margin: 4px 4px 4px 1px;
    padding: 3px 15.1px 3px 15.1px;
    border-radius: 16px;
    background-color: #ecf3f8;
    justify-items: center;
    align-content: center;
    text-align: center;
  }
  .btn-primary{
    width: 100%;
    height: 52px;
    flex-grow: 0;
    margin: 4px 1px 4px 4px;
    padding: 10px 15px 10px;
    border-radius: 8px;
    background-color: #2e60f5;
  }
  .opacity-style {
    opacity: 0.7;
  }
  .divider-style {
    margin: 0;
    padding: 0;
    &.small-margin {
      margin-top: 4px;
      margin-bottom: 4px;
    }
    &.large-margin {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
}
