/* AdviserDetails.less */

.adviser-details-container {
  margin-top: 24px;
  display: flex;
  justify-content: space-evenly;

  .profile-card {
    padding: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    text-align: center;

    .profile-picture-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 300px;

      .profile-picture {
        width: 200px;
        height: 200px;
        border-radius: 50%;
      }
    }

    .meta-container {
      margin-top: 16px;
    }
    .schedule-item {
      background: lightyellow;
      border: 1px solid #d3d3d3;
      padding: 5px;
      margin-top: 5px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      font-family: cursive;
    }
    .schedule-divider {
      background: lightyellow;
    }
    .ant-divider {
      margin: 8px 0;
    }

    .price-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .price-text {
      margin-right: 8px;
      font-size: 16px;
    }

    .reservation-button {
      width: 80%;
      background-color: #001529;
      border-color: #001529;
      color: #e8e8e8;
      &:hover {
        background-color: #e8e8e8;
        border-color: #e8e8e8;
        color: #001529;
      }
    }

    .info-text {
      margin-bottom: 8px;
    }
  }
}

.about-container {
  margin-bottom: 20px;

  .ant-divider {
    margin: 8px 0;
  }

  /* Menu (Tabs) styles */
  .menu {
    margin-top: 1rem;
  }

  #about-me,
  #about-class {
    font-size: 16px;
    line-height: 1.6;

    .read-more-button {
      margin-top: 10px;
    }
  }
}

.subject-card {
  background-color: #f0f2f5;
  margin-bottom: 16px;

  .subject-title {
    font-size: 18px;
    font-weight: bold;
  }

  .subgroup-tag {
    font-size: 15px;
    padding: 4px;
  }
}
