.drawer-chat-container {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1;
}

.drawer-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawer-list-item {
  cursor: pointer;

  &:hover {
    background-color: #e6f7ff;
  }
}

.drawer-selected-item {
  background: #e6f7ff;
}

.chat-box-container {
  height: 100%;
  overflow-y: auto;
}
